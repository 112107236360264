import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {LocationStrategy, HashLocationStrategy, registerLocaleData} from '@angular/common';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {JwtModule} from '@auth0/angular-jwt';


import {AppComponent} from './app.component';

// Import containers
import {DefaultLayoutComponent} from './containers';
import {SimpleLayoutComponent} from './containers/simple-layout/simple-layout.component';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';


import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {AuthGuard} from './model/auth.guard';
import {StaffService} from './model/staff.service';
import {StaffDataService} from './model/staff-data.service';
import {GlobalService} from './model/global.service';
import {SettingDataService} from './model/setting-data.service';
import {FsDataService} from './model/fs-data.service';
import {DashboardService} from './model/dashboard.service';

import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {CoreuiModule} from './coreui/coreui.module';

import {setAppInjector} from './model/app-injector';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedService} from './shared/shared.service';
import {NgZorroAntdModule, NZ_I18N, en_US, NzModalService} from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import en from '@angular/common/locales/en';
import {AppModalService} from './shared/modal-helper';
import {AppMessageService} from './shared/message-helper';
import {BsDatepickerModule} from 'ngx-bootstrap';
import {RepaymentRecordService} from './modules/loan/model/repayment-record.service';

registerLocaleData(en);

const APP_CONTAINERS = [
    DefaultLayoutComponent,
    SimpleLayoutComponent,
];

export function tokenGetter() {
    return sessionStorage.getItem(environment.tokenName);
}
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        HttpClientModule,
        SharedModule,
        CoreuiModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: [environment.apiHost]
            }
        }),
        BsDatepickerModule.forRoot(),
        NgZorroAntdModule,
        FormsModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
    ],
    exports: [
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        AuthGuard,
        SharedService,
        StaffService,
        StaffDataService,
        GlobalService,
        SettingDataService,
        FsDataService,
        DashboardService,

        NzModalService,
        AppModalService,
        AppMessageService,
        RepaymentRecordService,

        { provide: NZ_I18N, useValue: en_US },



    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        setAppInjector(injector);
    }
}
