import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {AuthGuard} from './model/auth.guard';
import {SimpleLayoutComponent} from './containers/simple-layout/simple-layout.component';

export const routes: Routes = [
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404'
        }
    },
    {
        path: '500',
        component: P500Component,
        data: {
            title: 'Page 500'
        }
    },
    {
        path: 'login',
        component: SimpleLayoutComponent,
        loadChildren: './views/login/login.module#LoginModule'
    },
    {
        path: 'forgot-password',
        component: SimpleLayoutComponent,
        loadChildren: './views/forgot-password/forgot-password.module#ForgotPasswordModule'
    },
    {
        path: 'password-reset',
        component: SimpleLayoutComponent,
        loadChildren: './views/password-reset/password-reset.module#PasswordResetModule'
    },
    {
        path: 'logout',
        component: SimpleLayoutComponent,
        loadChildren: './views/logout/logout.module#LogoutModule'
    },
    {
        path: 'coreui',
        component: SimpleLayoutComponent,
        loadChildren: './coreui/coreui.module#CoreuiModule'
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: './views/dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'setting',
                loadChildren: './views/setting/setting.module#SettingModule'
            },
            {
                path: 'staff',
                loadChildren: './views/staff/staff.module#StaffModule'
            },
            {
                path: 'user',
                loadChildren: './views/user/user.module#UserModule'
            },
            {
                path: 'fund',
                loadChildren: './modules/fund/fund.module#FundModule'
            },
            {
                path: 'logs',
                loadChildren: './modules/logs/logs.module#LogsModule'
            },
            {
                path: 'loan',
                loadChildren: './modules/loan/loan.module#LoanModule'
            },
            {
                path: 'invoice',
                loadChildren: './modules/invoice/invoice.module#InvoiceModule',
                data: {
                    title: 'Invoice'
                }
            },
            {
                path: 'report',
                loadChildren: './modules/reports/reports.module#ReportsModule',
                data: {
                    title: 'Report'
                }
            },
            {
                path: 'crowd-funding',
                loadChildren: './modules/crowd-funding/crowd-funding.module#CrowdFundingModule'
            },
            {
                path: 'test',
                loadChildren: './views/test/test.module#TestModule'
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
            },
        ]
    },
    {path: '**', component: P404Component}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
