export class ErrorResponse {

    code: number;
    name: string;
    message: string;
    status: number;

    _errorFields: any;

    isErrorFields() {
        return this.status && this.code === 4220;
    }

    errorFields() {
        return this._errorFields;
    }

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (this.isErrorFields()) {
            this._errorFields = JSON.parse(this.message);
            this.message = null;
        }
    }
}

export class ResponseBody {
    status: number;
    success: boolean;
    data: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
