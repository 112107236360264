import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';
import {Observable} from "rxjs";

@Injectable()
export class DashboardService {

    loggedIn: boolean;

    constructor(
        private globalService: GlobalService,
        private http: HttpClient
    ) {
        this.loggedIn = this.globalService.isLoggedIn();
    }

    public get(): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http.get<ResponseBody>(
            this.globalService.apiHost + '/dashboard',
            {headers: headers}
        ).pipe(
            map(response => {
                return <any>response.data;
            }),
            catchError(GlobalService.handleError)
        );

    }

}
