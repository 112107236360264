import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {SpinnerComponent} from './spinner/spinner.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {OwlMomentDateTimeModule} from 'ng-pick-datetime-moment';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {UploaderComponent} from './uploader/uploader.component';
import {BsDatepickerModule, BsModalService, DatepickerModule, ModalModule, ProgressbarModule, TypeaheadModule} from 'ngx-bootstrap';
import {CommonModule} from '@angular/common';
import {NumberFormatter} from './formatter/numberFormatter';
import {CurrencyFormatter} from './formatter/currencyFormatter';
import {IndeterminateDirective} from './directives';
import {PageHeaderComponent} from './views/page-header/page-header.component';
import {RouterModule} from '@angular/router';
import {DecimalFormatPipe, NumberFormatPipe, CurrencyFormatPipe} from './formatter/number-format.pipe';
import {TimepickerComponent} from './timepicker/timepicker.component';
import {FormGroupComponent} from './views/form-group/form-group.component';
import {MatDatepickerModule} from '@angular/material';
import {NzSelectModule} from 'ng-zorro-antd/select';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// export const CUSTOM_DATETIME_FORMATS = environment.customDateTimeFormat;

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,

        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        OwlNativeDateTimeModule,

        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RouterModule,

        DatepickerModule,
        BsDatepickerModule.forRoot(),

        TypeaheadModule.forRoot(),

        NzSelectModule,
        ModalModule,

    ],
    declarations: [
        SpinnerComponent,
        UploaderComponent,
        TimepickerComponent,

        // Directives
        IndeterminateDirective,
        PageHeaderComponent,

        // Pipes
        NumberFormatPipe,
        DecimalFormatPipe,
        CurrencyFormatPipe,
        // CurrencyFormat2Pipe,

        FormGroupComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        SpinnerComponent,
        UploaderComponent,

        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        OwlNativeDateTimeModule,

        PaginationModule,

        PageHeaderComponent,
        FormGroupComponent,
        NumberFormatPipe,
        DecimalFormatPipe,
        CurrencyFormatPipe,
        // CurrencyFormat2Pipe,

        MatDatepickerModule,
        TimepickerComponent,
    ],

    providers: [

        // MAT used by material date picker
        // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        // {provide: MAT_DATE_FORMATS, useValue: environment.MY_MAT_DATE_FORMATS},

        // OWL datetime picker
        // {provide: OWL_DATE_TIME_FORMATS, useValue: environment.MY_OWL_DATE_TIME_FORMATS},
        // {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-SG'},

        NumberFormatter,
        CurrencyFormatter,
        BsModalService,
    ],
})
export class SharedModule {
}
