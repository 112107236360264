import {Component, OnDestroy, Inject, OnInit} from '@angular/core';

import {DOCUMENT} from '@angular/common';
import {NavData, navItems} from '../../_nav';
import * as moment from 'moment';
import {StaffService} from '../../model/staff.service';
import {StatsService} from '../../model/stats.service';
import {Stats} from '../../model/stats';
import {Staff} from '../../model/staff';
import _ from 'lodash';
import {environment} from '../../../environments/environment';
import {SettingDataService} from '../../model/setting-data.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
    public defaulNavItems = navItems;
    public navItems: NavData[] = [];
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement;

    public year;
    public userData: any = {};
    public permissions: any = {};
    public stats: Stats;
    public badges: any;
    meData: Staff;

    version = environment.version;

    constructor(
        public staffService: StaffService,
        private statsService: StatsService,
        private settingDataService: SettingDataService,
        @Inject(DOCUMENT) _document?: any
    ) {

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
        });
        this.element = _document.body;
        this.changes.observe(<Element>this.element, {
            attributes: true,
            attributeFilter: ['class']
        });
        this.year = moment().format('Y');

    }

    ngOnInit(): void {

        this.settingDataService.refreshGlobalSettings();
        this.navItems = [];

        const jwtValue: any = this.staffService.getJWTValue();
        if (jwtValue !== null) {
            this.userData = jwtValue.data;
        }

        this.staffService.getMe().subscribe(
            staff => {
                this.staffService.setPermissions(staff._permissions);
                this.checkNavRecursively(this.defaulNavItems, this.navItems);
                this.meData = staff;
            }
        );

        console.log(this.userData);
        console.log(this.userData.uid);

        // setTimeout(() => {
        //     this.statsService.updateNavBadges();
        // }, 1000 * 60);
        //
        // this.statsService.observeBadgesStat().subscribe(
        //     badges => {
        //         this.badges = badges;
        //         if (this.badges) {
        //
        //             const temp = [];
        //             this.navItems.forEach((nav) => {
        //
        //                 const temp1 = nav;
        //                 _.forIn(this.badges, (total, key) => {
        //                     if (nav.total_key_id == key) {
        //                         if (total == 0) {
        //                             temp1['badge'] = null;
        //                         } else {
        //                             temp1['badge'] = {
        //                                 'text': total,
        //                                 'variant': 'danger'
        //                             };
        //                         }
        //                     }
        //                 });
        //
        //                 if (nav.children) {
        //
        //                     const temp_children = [];
        //                     nav.children.forEach((nav2) => {
        //
        //                         const temp2 = nav2;
        //                         _.forIn(this.badges, (total, key) => {
        //                             if (nav2.total_key_id == key) {
        //                                 if (total == 0) {
        //                                     temp2['badge'] = null;
        //                                 } else {
        //                                     temp2['badge'] = {
        //                                         'text': total,
        //                                         'variant': 'danger'
        //                                     };
        //                                 }
        //                             }
        //                         });
        //                         temp_children.push(temp2);
        //
        //                     });
        //                     temp1.children = temp_children;
        //
        //                 }
        //                 temp.push(temp1);
        //
        //             });
        //
        //             this.navItems = temp;
        //
        //         }
        //
        //     }
        // );

    }


    ngOnDestroy(): void {
        this.changes.disconnect();
    }

    getNavById(id) {
        let navi;
        this.navItems.forEach((nav) => {
            if (navi == null && nav.id && nav.id === id) {
                navi = nav;
            }
        });
        return navi;
    }

    checkNavRecursively(navs, _newnav) {

        navs.forEach((nav, index) => {
            if (nav.children) {

                const _new_child_nav = Object.assign({}, nav);
                const _new_child_children = [];

                this.checkNavRecursively(nav.children, _new_child_children);

                _new_child_nav.children = _new_child_children;
                if (nav.permissions) {
                    if (this.staffService.can(nav.permissions)) {
                        _newnav.push(_new_child_nav);
                    }
                } else {
                    _newnav.push(_new_child_nav);
                }

            } else {
                if (nav.permissions) {
                    if (this.staffService.can(nav.permissions)) {
                        _newnav.push(nav);
                    }
                } else {
                    _newnav.push(nav);
                }
            }
        });

    }

}
