export const environment = {
    production: false,
    version: 'v1.0.1',
    apiHost: '//gkw-mfi-api.pilon.sg/v1',
    tokenName: 'backend-token',
    xdebug: false,

    MY_OWL_DATE_TIME_FORMATS: {
        apiFormat: 'YYYY-MM-DD HH:mm:ss',
        parseInput: 'YYYY-MM-DD HH:mm:ss',
        fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
        datePickerInput: 'YYYY-MM-DD',
        timePickerInput: 'HH:mm:ss',
        monthYearLabel: 'YYYY-MM',
        dateA11yLabel: 'YYYY-MM-DD',
        monthYearA11yLabel: 'YYYY-MM',
    },

    bsDatePickerConfig: {containerClass: 'theme-blue', dateInputFormat: 'YYYY-MM-DD'}

};

