import {Injectable} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd';


@Injectable()
export class AppMessageService {

    constructor(
        private message: NzMessageService,
    ) {
    }

    loading(message = 'Loading...') {
        this.message.loading(message, {nzDuration: 500});
    }

    success(message) {
        this.message.create('success', message);
    }

    error(message) {
        this.message.create('error', message);
    }

    warning(message) {
        this.message.create('warning', message);
    }

    info(message) {
        this.message.create('info', message);
    }

    // Preset messages
    errorPleaseFillRequiredFields() {
        this.message.create('error', 'Please fill up required fields.');
    }

}
