import {catchError, map} from 'rxjs/operators';


import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

import {environment} from '../../environments/environment';
import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';
import {BehaviorSubject, Observable} from 'rxjs';
import {Staff} from "./staff";

@Injectable()
export class StaffService {
    redirectURL = '';
    loggedIn = false;
    parsedPermission: any[];

    private userDataSource = new BehaviorSubject({});
    public observeUserData = this.userDataSource.asObservable();

    constructor(
        private globalService: GlobalService,
        private router: Router,
        private jwtHelper: JwtHelperService,
        private http: HttpClient
    ) {
        this.loggedIn = this.globalService.isLoggedIn();
    }

    public login(username, password) {
        const headers = GlobalService.getHeaders();

        return this.http
            .post<ResponseBody>(
                GlobalService.getUrl('/staff/login'),
                JSON.stringify({
                    username: username,
                    password: password,
                }),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    if (response.success) {

                        sessionStorage.setItem(
                            environment.tokenName,
                            response.data['access_token']
                        );
                        this.setPermissions(response.data['permissions']);
                        // this.parsedPermission = response.data['permissions'];
                        this.loggedIn = true;

                    } else {
                        sessionStorage.removeItem(environment.tokenName);
                        this.loggedIn = false;
                    }
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    unauthorizedAccess(error: any): void {
        this.logout();
        this.router.navigate(['/login'], {queryParams: {error: error.data.message}});
    }

    logout(): void {
        sessionStorage.removeItem(environment.tokenName);
        this.loggedIn = false;
    }

    getJWTValue(): any {
        if (this.globalService.isLoggedIn()) {
            const token = GlobalService.getToken();
            return this.jwtHelper.decodeToken(token);
        } else {
            return null;
        }
    }

    isRoleBuyer() {
        return this.getRoleId() == 'buyer';
    }

    getRoleId() {

        const jwtValue: any = this.getJWTValue();
        if (jwtValue !== null) {
            const userData = jwtValue.data;
            return userData.roleid;
        }
        return null;
    }

    getRole() {

        const jwtValue: any = this.getJWTValue();
        if (jwtValue !== null) {
            const userData = jwtValue.data;
            return userData.roleLabel;
        }
        return null;
    }

    public getMe(): Observable<Staff> {

        const headers = GlobalService.getHeaders();
        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/staff/me'),
                {headers: headers}
            ).pipe(
                map(response => {
                    return new Staff(response.data);
                }),
                catchError(GlobalService.handleError)
            );

    }

    setPermissions(permissions) {
        // sessionStorage.setItem('permissions', JSON.stringify(permissions));
        this.parsedPermission = permissions;
    }

    getPermissions(): any[] {
        if (this.globalService.isLoggedIn()) {
            // if (this.parsedPermission == null) {
            //     this.parsedPermission = JSON.parse(sessionStorage.getItem('permissions'));
            // }
            console.log(this.parsedPermission)
            return this.parsedPermission;
        } else {
            return [];
        }
    }

    role(permissions: string[]) {
        const allPermissions = this.getPermissions();
        let found = false;

        allPermissions.forEach((_permission, index) => {
            permissions.forEach((_permission2, index2) => {
                if (!found && _permission === _permission2) {
                    found = true;
                }
            });
        });
        return found;
    }

    can(permissions: string[]) {

        // admin must always can for anything
        permissions.push('superadmin');
        permissions.push('admin');

        const allPermissions = this.getPermissions();
        let found = false;
        if (allPermissions) {
            // console.log(allPermissions);
            allPermissions.forEach((_permission, index) => {
                permissions.forEach((_permission2, index2) => {
                    if (!found && _permission === _permission2) {
                        found = true;
                    }
                });
            });
        }
        return found;

    }


    public getData(fromServer?: boolean): Observable<Staff> {

        const _userData = localStorage.getItem('staff');
        if (_userData) {
            const user = JSON.parse(_userData);
            this.userDataSource.next(user);
        }
        if (fromServer) {
            this.fetchServerData();
        }
        return this.observeUserData.pipe(map(response => {
            return (new Staff(response));
        }));

    }

    public setLocalData(userData: any) {
        this.userDataSource.next(userData);
        localStorage.setItem(
            'staff',
            JSON.stringify(userData)
        );
    }

    public fetchServerData() {
        if (this.loggedIn) {
            this.getMe().subscribe(user => {
                this.setLocalData(user);
            });
        }
    }

    public resetLink(email, role) {
        const headers = GlobalService.getHeaders();

        return this.http
            .post<ResponseBody>(
                GlobalService.getUrl('/user/password-reset-request'),
                JSON.stringify({
                    email: email,
                    role: role
                }),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    if (response.success) {
                    } else {
                        sessionStorage.removeItem(environment.tokenName);
                        this.loggedIn = false;
                    }
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    public passwordReset(payload) {
        const headers = GlobalService.getHeaders();
        return this.http
            .post<ResponseBody>(
                GlobalService.getUrl('/user/password-reset'),
                JSON.stringify(payload),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    if (response.success) {
                    } else {
                        sessionStorage.removeItem(environment.tokenName);
                        this.loggedIn = false;
                    }
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    public passwordResetTokenVerification(token) {
        const headers = GlobalService.getHeaders();

        return this.http
          .post<ResponseBody>(
            GlobalService.getUrl('/user/password-reset-token-verification'),
            {token: token},
            {headers: headers}
          ).pipe(
            map(response => {
              if (response.success) {
              } else {
              }
              return response;
            }),
            catchError(GlobalService.handleError));
      }
}
