import {BaseModel} from '../base-model';

export const KycRequestStatic = {
    STATUS_PENDING: 0,
    STATUS_REJECTED: 1,
    STATUS_APPROVED: 2,
    STATUS_ARTEMIS_PENDING: 3,

    ACTION_APPROVE: 'approve',
    ACTION_REJECT: 'reject',

    TYPE_GENERAL: 1,
    TYPE_BANK: 2

};


export class KycRequestBase extends BaseModel {

    id: number;
    status: number;
    status_label: string;
    reason: string;
    kyc_type: number;

    _reject_loader = false;
    _approve_loader = false;

    isPending() {
        return this.status === KycRequestStatic.STATUS_PENDING;
    }

    isPendingArtemis() {
        return this.status === KycRequestStatic.STATUS_ARTEMIS_PENDING;
    }

    isRejected() {
        return this.status === KycRequestStatic.STATUS_REJECTED;
    }

}
