import {BaseModel} from './base-model';
import _ from 'lodash';

export class Staff extends BaseModel {
    id: number;
    row_num: number;
    username: string;
    email: string;
    password: string;
    role: string;
    role_label: string;
    last_login_at: string;
    last_login_ip: string;
    confirmed_at: string;
    blocked_at: string;
    status: number;
    status_label: string;
    created_at: string;
    updated_at: string;
    photo_url: string;

    permissionTree: StaffPermissionTree[];
    permissions: StaffPermissionTree[];
    _permissions: string[];
    treeIndex = 0;
    permissionControlIndex = 0;

    init(value) {

        this.treeIndex = 0;
        this.permissionControlIndex = 0;
        this.permissions = [];
        if (value.permissions) {
            this.permissionTree = this._setPermissionTree(value.permissions);
        }
    }

    _setPermissionTree(permissions, parent = null, top = null) {
        const permTree = [];
        _.forIn(permissions, (perm, permKey) => {
            this.treeIndex += 1;
            const _index = this.treeIndex;
            if (permKey.search('group:') === 0) {

                const group = new StaffPermissionTree({
                    name: permKey,
                    isGroup: true,
                    index: _index,
                    parent: parent,
                    top: top,
                });
                const _top  = top || group;
                group.permissions = this._setPermissionTree(perm, group, _top);
                permTree.push(group);

            } else {

                const pp = new StaffPermissionTree(Object.assign(perm, {
                    isGroup: false,
                    index: _index,
                    controlIndex: this.permissionControlIndex,
                    parent: parent,
                    top: top,
                }));
                permTree.push(pp);
                this.permissions.push(pp);
                this.permissionControlIndex += 1;

            }

        });
        return permTree;

    }


}

export class StaffPermissionTree extends BaseModel {

    name: string;
    description: string;
    checked: boolean;
    indeterminate: boolean;

    index: number;
    controlIndex: number;

    isGroup: boolean;
    permissions: any[];

    group: StaffPermissionTree;
    top: StaffPermissionTree;

    getName() {
        if (this.name) {
            return this.name
                .replace(/([A-Z])/g, ' $1')
                .replace('group:', '')
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                });
        } else {
            return '';
        }
    }
}
