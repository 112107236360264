import {catchError, map} from 'rxjs/operators';


import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {GlobalService} from './global.service';
import {Staff} from './staff';
import {StaffService} from './staff.service';
import {ResponseBody} from './response-body';
import {StaffList} from './staff-list';

@Injectable()
export class StaffDataService {

    constructor(
        private globalService: GlobalService,
        private staffService: StaffService,
        private http: HttpClient
    ) {
    }

    public static getStatusTypes(): Array<any> {
        return [
            {
                label: 'Active',
                value: 10
            },
            {
                label: 'Waiting Confirmation',
                value: 1
            },
            {
                label: 'Disabled',
                value: 0
            }
        ];
    }

    public static getRoleTypes(): Array<any> {
        return [
            {
                label: 'Staff',
                value: 'staff'
            },
            // {
            //     label: 'Buyer',
            //     value: 'buyer'
            // }
        ];
    }

    // POST /v1/staff
    addStaff(staff: any): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .post<ResponseBody>(
                this.globalService.apiHost + '/staff',
                JSON.stringify(staff),
                {headers: headers}
            ).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // DELETE /v1/staff/1
    deleteStaffById(id: number): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .delete<ResponseBody>(
                GlobalService.getUrl('/staff/' + id),
                {headers: headers}
            ).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // PUT /v1/staff/1
    updateStaffById(staff: any): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .put<ResponseBody>(
                GlobalService.getUrl('/staff/' + staff.id),
                JSON.stringify(staff),
                {headers: headers}
            ).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // GET /v1/staff
    getAllStaffs(extendedQueries?: any): Observable<StaffList> {
        const headers = GlobalService.getHeaders();

        let queries = {};
        if (extendedQueries) {
            queries = Object.assign(queries, extendedQueries);
        }

        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/staff', extendedQueries),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    return new StaffList(response.data);
                }),
                catchError(GlobalService.handleError));
    }

    // GET /v1/staff/1
    getStaffById(id: number): Observable<Staff> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/staff/' + id),
                {headers: headers}
            ).pipe(
                map(response => {
                    return (new Staff(response.data));
                }),
                catchError(GlobalService.handleError));
    }

    public getPermissionTypes(): Observable<Array<any>> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/staff/all-permissions'),
                {headers: headers}
            ).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }

}
