import {Fs} from '../fs';

export class Document extends Fs {

    file: string;
    filename: string;
    type: string;
    type_label: string;

}
