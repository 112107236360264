import swal from 'sweetalert2';
import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

export function modalClose() {
    swal.close();
}

export function modalError(text) {
    swal.fire({
        type: 'error',
        text: text
    });
}

export function modalShowLoading() {
    swal.showLoading();
}

export function modalHideLoading() {
    swal.hideLoading();
}

export function modalSuccess(text?, callback = null) {
    swal.fire({
        type: 'success',
        text: text,
        onClose: () => {
            if (callback) {
                callback();
            }
        }
    });
}

export function modalInfo(text?, callback = null) {
    swal.fire({
        type: 'info',
        text: text,
        onClose: () => {
            if (callback) {
                callback();
            }
        }
    });
}


export async function modalInput(text, onConfirm) {

    const inputValue = '';
    const {value: inputVal} = await swal.fire({
        title: text,
        input: 'text',
        inputValue: inputValue,
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'You need to write something!';
            }
        }
    });

    if (inputVal) {
        onConfirm(inputVal);
    }

}

export function modalConfirm(text, onConfirm, onCancel?) {

    if (text == null) {
        text = 'Are you sure?';
    }

    swal.fire({
        title: text,
        text: '',
        type: 'question',
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm!',
        preConfirm: () => {

            return new Promise((resolve, reject) => {
                if (onConfirm) {
                    onConfirm(resolve, reject);
                }
            });

        }

    }).then(
        function (result) {
            if (result.dismiss && onCancel) {
                onCancel();
            }
            // onCancel(result);
            // handle confirm, result is needed for modals with input
        }, function (error) {
            // dismiss can be "cancel" | "close" | "outside"
        }
    );
}

export class AppModalRef {
    ref: NzModalRef;
    loading = false;

    close() {
        this.ref.close();
    }
}

@Injectable()
export class AppModalService {

    bsRef: BsModalRef;

    constructor(
        private modal: NzModalService,
        private bsModalService: BsModalService
    ) {
    }

    openBsModal(tpl) {
        this.bsRef = this.bsModalService.show(tpl, {
            class: 'modal-fade modal-dialog-centered',
        });
    }

    closeBsModal() {
        if (this.bsRef) {
            this.bsRef.hide();
        }
    }

    inputText(text, onConfirm) {
        modalInput(text, onConfirm);
    }

    success(message) {
        modalSuccess(message);
    }

    info(message, cb = null) {
        modalInfo(message, cb);
    }

    error(message) {
        modalError(message);
    }

    close() {
        modalClose();
    }

    confirm(text?, callback = null): AppModalRef {

        const modalRef = new AppModalRef();
        modalRef.loading = false;

        const resolve = () => {
            modalRef.close();
        };

        modalRef.ref = this.modal.confirm({
            nzTitle: text,
            nzContent: '',
            nzOnOk: () => {
                callback(resolve);
            }

        });
        return modalRef;

    }

    loading() {
        this.close();
    }

    hideLoading() {
        modalHideLoading();
    }

}
