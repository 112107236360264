import {BaseModel} from './base-model';
import {BankDetails} from './user-onboarding/bank-details';
import {SourceOfWealth} from './user-onboarding/source-of-wealth';
import {Crp} from './user-onboarding/crp';
import {Identification} from './user-onboarding/identification';
import {Document} from './user-onboarding/document';
import {KycRequestBase} from './user-onboarding/kyc-request-base';
import {FormField, FormFields, FormFieldsArray} from "../shared/form-helper";

class Address extends BaseModel {
    country_id: string;
    postal: string;
    line1: string;
    line2: string;
    unit_no: string;
}

class Security extends BaseModel {
    otp_enabled: boolean;
}

class PersonalInfo extends BaseModel {

    full_name: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    gender: string;
    marital_status: string;
    mobile_cc: string;
    mobile_no: string;
    birth_date: string;
    nationality: string;
    citizen_type: number;
    is_editable: boolean;

    address: Address;
    identification: Identification;
    bank: BankDetails;
}

class FinancialInfo extends BaseModel {
    career_status: number;
    sourceOfWealths: SourceOfWealth[];
}

class Company {
    name: string;
    registration_no: string;
    registration_issued_date: string;
    registration_expiry_date: string;
    office_no: string;
    industry_id: string;
}

class TinInfo {
    country: String;
    tin: String;
}

class LoanSummary extends BaseModel {
    total_outstanding: number;
    credit_limit: number;
    max_loan_amount: number;
}

class FundSummary extends BaseModel {
    balance: number;
    available: number;
    on_hold: number;
    ongoing_investment_sum: number;
    ongoing_investment_count: number;
    pending_pledge: number;
    dividends: number;
}

class BorrowerFundSummary extends BaseModel {
    balance: number;
}

class UserDocuments {
    identity_card_front: Document;
    identity_card_back: Document;

    director_identity_card_front: Document;
    director_identity_card_back: Document;

    // passport_colored: Document;
    // passport_certified: Document;
    // passport_holding: Document;
    // residential_address: Document;
    //
    // company_bizfile: Document;
    // company_article_ma: Document;
    // company_bank_statement: Document;

    company_cert_incorp: Document;
    driver_photo_with_car: Document;
    license_no_front: Document;
    license_no_back: Document;
    commercial_insurance: Document;
}

class Declaration extends BaseModel {
    is_pep_exposed: boolean;
    pep_fullname: string;
    pep_relationship: string;
    pep_status: string;
    is_sole_tax_resident: boolean;
    tin_list: TinInfo[];
    is_us_person_tax_resident: boolean;
    us_tin: string;
}

class DriverInfo extends BaseModel {
    vehicle_no: string;
    license_no: string;
    vehicle_ownership: string;
    comm_insurance_no: string;
    comm_insurance_expire: string;
    car_model: string;
}

class WingInfo extends BaseModel {
    merchant_no: string;
    ave_daily_revenue: string;
    ave_monthly_revenue: string;
}

class SalaryRecord extends BaseModel {
    file: string;
    filename: string;
    url: string;
    is_image: boolean;
    salary_date: string;
    total_months: number;
    note: string;
}

export class Employment extends BaseModel {
    id: string;
    company_name: string;
    job_title: string;
    website: string;
    work_period: string;
    monthly_salary: string;
    address: Address;
    salaryRecords: SalaryRecord[];

    init(value) {
        this.salaryRecords = [];
        if (value.salaryRecords) {
            value.salaryRecords.forEach((val) => {
                this.salaryRecords.push(val);
            });
        }
        super.init(value);
    }

}

class BusinessInfo extends BaseModel {
    business_type: number;
    driverInfo: DriverInfo;
    wingInfo: WingInfo;
    registeredAddress: Address;
    bank: BankDetails;
    company: Company;

    init(value) {
        if (value.driverInfo) {
            this.driverInfo = new DriverInfo(value.driverInfo);
        }
        if (value.wingInfo) {
            this.wingInfo = new WingInfo(value.wingInfo);
        }
        if (value.registeredAddress) {
            this.registeredAddress = new Address(value.registeredAddress);
        }
    }

}


export const UserStatic = {

    ACCOUNT_TYPE_INVESTOR: 1,
    ACCOUNT_TYPE_SUPPLIER: 2,
    ACCOUNT_TYPE_BUYER: 3,

    STATUS_DISABLED: 0, // User::STATUS_DISABLED
    STATUS_PENDING: 1, // User::STATUS_PENDING
    STATUS_ACTIVE: 10, // User::STATUS_ACTIVE

    KYC_STATUS_DISABLED: 0,
    KYC_STATUS_INCOMPLETE: 1,
    KYC_STATUS_PENDING_APPROVAL: 2,
    KYC_STATUS_APPROVED: 3,
    KYC_STATUS_REJECTED_RESUBMIT: 4,
    KYC_STATUS_PENDING_ARTEMIS: 5,

    KYC_TYPE_BORROWER_BUSINESS: 1,
    KYC_TYPE_BORROWER_PERSONAL: 2,
    KYC_TYPE_INVESTOR_PERSONAL: 3,
    KYC_TYPE_INVESTOR_BUSINESS: 4, //
    KYC_TYPE_BUSINESS: 5, // not investor or borrower

    DASHBOARD_BORROWER: 1,
    DASHBOARD_INVESTOR: 2,

    getStatusOptions: function () {
        return [
            {value: this.STATUS_ACTIVE, label: 'Active'},
            {value: this.STATUS_DISABLED, label: 'Disabled'},
        ];
    },

    getBuyerStatusOptions: function () {
        return [
            {value: this.STATUS_ACTIVE, label: 'Active'},
            {value: this.STATUS_DISABLED, label: 'Disabled'},
        ];
    },

    getKycStatusOptions: function () {
        return [
            {value: this.KYC_STATUS_PENDING_APPROVAL, label: 'Pending Approval'},
            {value: this.KYC_STATUS_INCOMPLETE, label: 'Incomplete'},
            {value: this.KYC_STATUS_APPROVED, label: 'Approved'},
        ];
    },

    getInvestorTypeOptions: function () {
        return [
            {value: this.KYC_TYPE_INVESTOR_PERSONAL, label: 'Individual'},
            {value: this.KYC_TYPE_INVESTOR_BUSINESS, label: 'Corporate'},
        ];
    },
    getAccountTypeOptions: function () {
        return [
            {value: this.ACCOUNT_TYPE_INVESTOR, label: 'Investor'},
            {value: this.ACCOUNT_TYPE_SUPPLIER, label: 'Supplier'},
            {value: this.ACCOUNT_TYPE_BUYER, label: 'Buyer'},
        ];
    },

    isBusiness: function (id) {
        return (id === UserStatic.KYC_TYPE_BORROWER_BUSINESS || id === UserStatic.KYC_TYPE_INVESTOR_BUSINESS || id === UserStatic.KYC_TYPE_BUSINESS);
    }
};

class Director extends BaseModel {

    first_name: string;
    last_name: string;

    address: Address;
    identification: Identification;
    bank: BankDetails;
}

export class Artemis extends BaseModel {
    id: number;
    created_at: string;
    updated_at: string;
    user_id: number;
    kyc_type: number;
    status: string;
    ref_id: string;
    data: string;
    is_pending: boolean;
    is_accepted: boolean;
}

export class User extends BaseModel {

    user_type: number;
    matrix: any;
    is_matrix: number;
    matrix_count_per_user_type: number;

    id: string;
    row_num: number;
    account_type: number;
    account_type_label: string;
    username: string;
    email: string;
    password: string;
    full_name: string;
    last_login_at: string;
    last_login_ip: string;
    confirmed_at: string;
    blocked_at: string;

    active_dashboard: number;
    mobile_cc: string;
    mobile_no: string;

    status: number;
    status_label: string;

    // KYC status
    borrower_personal_status: number;
    borrower_business_status: number;
    investor_type: number;
    investor_personal_status: number;
    investor_business_status: number;

    current_kyc_status: number;
    current_kyc_status_label: number;

    created_at: string;
    updated_at: string;

    progress: string;

    personalInfo: PersonalInfo;
    director: Director;
    identification: Identification;
    banks: BankDetails[];
    financialInfo: FinancialInfo;
    declaration: Declaration;
    company: Company;
    crp: Crp[];
    documents: UserDocuments;
    fundSummary: FundSummary;
    borrowerFundSummary: BorrowerFundSummary;
    loanSummary: LoanSummary;
    businessInfo: BusinessInfo;
    pendingKycRequest: KycRequestBase;
    employment: Employment;
    photo_url: string;

    artemis: Artemis;
    artemis_status: string;
    is_admin: boolean;
    sap_id: string;
    client_code: string;
    rbank_eipp_user_id: string;

    security: Security;

    applied_interest_rate: string;
    meta_value: string;

    url: string;
    client_id: string;
    key: string;

    first_name: string;
    last_name: string;

    company_name: Company;
    name: string;
    registration_no: string;
    industry_id: string;

    country_id: string;
    postal: string;
    line1: string;
    line2: string;
    unit_no: string;

    default_credit_limit: string;
    default_interest_rate: string;

    _form: BuyerListsControlFormFields;

    credit_limit: number;
    // company_name: string;
    supplier_code: string;
    interest_rate: number;
    credit_limit_status: number;

    init(value) {

        this.crp = [];
        this.personalInfo = new PersonalInfo();
        this.identification = new Identification();

        if (value && value.personalInfo) {
            this.personalInfo = new PersonalInfo(value.personalInfo);
        }
        if (value && value.identification) {
            this.identification = new Identification(value.identification);
        }
        if (value && value.pendingKycRequest) {
            this.pendingKycRequest = new KycRequestBase(value.pendingKycRequest);
        }

        if (value && value.director) {
            this.director = new Director(value.director);
        }
        if (value && value.security) {
            this.security = new Security(value.security);
        }
        if (value && value.employment) {
            this.employment = new Employment(value.employment);
        }

        if (value && value.artemis) {
            this.artemis = new Artemis(value.artemis);
        }

        this.fundSummary = new FundSummary((value && value.fundSummary ? value.fundSummary : {}));
        this.borrowerFundSummary = new BorrowerFundSummary((value && value.borrowerFundSummary ? value.borrowerFundSummary : {}));
        this.loanSummary = new LoanSummary(((value && value.loanSummary) ? value.loanSummary : {}));
        if (value.businessInfo) {
            this.businessInfo = new BusinessInfo(value.businessInfo);
        } else {
            this.businessInfo = new BusinessInfo();
        }

        if (value && value.crp) {
            value.crp.forEach(crp => {
                const _crp = new Crp(crp);
                this.crp.push(_crp);
                console.log(this.crp);
            });
        }

    }

    getStatusClass() {
        return {
            'text-success': this.status === UserStatic.STATUS_ACTIVE,
            'text-warning': (this.status === UserStatic.STATUS_PENDING),
            'text-danger': (this.status === UserStatic.STATUS_DISABLED),
        };
    }

    getKycStatusClass() {
        return {
            'text-success': this.current_kyc_status === UserStatic.KYC_STATUS_APPROVED,
            'text-warning': (this.current_kyc_status === UserStatic.KYC_STATUS_PENDING_APPROVAL || this.current_kyc_status === UserStatic.KYC_STATUS_PENDING_ARTEMIS),
            'text-danger': (this.current_kyc_status === UserStatic.KYC_STATUS_DISABLED || this.current_kyc_status === UserStatic.KYC_STATUS_REJECTED_RESUBMIT || this.current_kyc_status === UserStatic.KYC_STATUS_INCOMPLETE),
        };
    }

    getPhotoUrl() {
        if (this.photo_url && this.photo_url.search('file') !== -1) {
            if (this.photo_url.search('\\\?')) {
                return this.photo_url + '&size=100x100';
            } else {
                return this.photo_url + '?size=100x100';
            }

        } else {
            return this.photo_url;
        }
    }

    displayName() {
        if (this.full_name) {
            return this.full_name;
        }
        if (this.personalInfo && this.personalInfo.full_name) {
            return this.personalInfo.full_name;
        }
        if (this.email) {
            return this.email;
        }
        return this.username;
    }

    fullname(defaultVal = '-No Name-') {
        if (this.full_name) {
            return this.full_name;
        } else if (this.email) {
            return this.email;
        } else {
            return defaultVal;
        }
    }

    mobileFull() {
        let m = '';
        if (this.mobile_cc) {
            m = this.mobile_cc;
        }
        if (this.mobile_no) {
            m += '' + this.mobile_no;
        }
        return m;
    }

    getGender() {
        if (this.personalInfo.gender === 'M') {
            return 'Male';
        } else if (this.personalInfo.gender === 'M') {
            return 'Female';
        }
        return '';
    }

    isActive() {
        return this.status === UserStatic.STATUS_ACTIVE;
    }

    activeDashboard() {
        if (this.active_dashboard == UserStatic.DASHBOARD_BORROWER) {
            return 'Borrower';
        } else if (this.active_dashboard == UserStatic.DASHBOARD_INVESTOR) {
            return 'Investor';
        }
    }

    isKycApproved() {

    }

    isBorrowerApproved() {
        return this.borrower_personal_status == UserStatic.KYC_STATUS_APPROVED || this.borrower_business_status == UserStatic.KYC_STATUS_APPROVED;
    }

    isInvestorApproved() {
        return this.investor_personal_status == UserStatic.KYC_STATUS_APPROVED || this.investor_business_status == UserStatic.KYC_STATUS_APPROVED;
    }

    isSupplier() {
        return this.account_type == UserStatic.ACCOUNT_TYPE_SUPPLIER;
    }

    isBuyer() {
        return this.account_type == UserStatic.ACCOUNT_TYPE_BUYER;
    }

    isInvestor() {
        return this.account_type == UserStatic.ACCOUNT_TYPE_INVESTOR;
    }

}

export class BuyerListsControlFormFields extends FormFields {
    id = new FormField();
    company_name = new FormField();
    supplier_code = new FormField();
    credit_limit = new FormField();
    interest_rate = new FormField();
    credit_limit_status = new FormField();
}

export class BuyerListsControlFormFieldsArray extends FormFieldsArray {
    formFieldsArray: BuyerListsControlFormFields[];
}
