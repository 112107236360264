import {BaseModel} from '../base-model';
import {Document} from './document';
import {Identification} from './identification';


class CrpDocuments extends BaseModel {
    crp_identity_card_front: Document;
    crp_identity_card_back: Document;
    crp_passport_colored: Document;
    crp_passport_certified: Document;
    crp_passport_holding: Document;
    crp_residential_address: Document;

    init(value) {
        if (value) {
            Object.keys(value).forEach((key) => {
                this[key] = new Document(value[key]);
            });
        }
    }

}

export class Crp extends BaseModel {
    id: string;
    title: string;
    full_name: string;
    first_name: string;
    last_name: string;
    middle_name: string;

    nationality: string;
    citizen_type: string;

    identification: Identification;
    documents: CrpDocuments;

    init(value) {
        if (value.documents) {
            this.documents = new CrpDocuments(value.documents);
        }
        if (value.identification) {
            this.identification = new Identification(value.identification);
        }
    }

    documentsArray(): Document[] {
        const _documentArr = [];
        if (this.documents) {
            for (const _key in this.documents) {
                if (this.documents[_key] instanceof  Document) {
                    _documentArr.push(this.documents[_key]);
                }
            }
        }
        return _documentArr;
    }

    getFullname() {
        if (this.full_name) {
            return this.full_name + '';
        } else if (this.first_name) {
            return ([this.first_name, this.last_name + '']).join(' ');
        }
    }

}
