import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ErrorResponse, ResponseBody} from './response-body';
import {SharedService} from '../shared/shared.service';
import {UrlFormatter} from '../shared/formatter/url-formatter';
import {AppInjector} from './app-injector';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {modalError} from '../shared/modal-helper';

export const ERROR_CODES = {
    CODE_ERROR_FIELDS: 4220
};

class GlobalSettings {
    currency_code: string;
    currency_symbol: string;
    minimum_investment_amount: string;
    risk_management_fee: string;
    minimum_deposit: string;
    deposit_bank_account: string;
}

@Injectable()
export class GlobalService {

    public apiHost: string;
    public setting: any = {};

    public qryDataList: any = {};
    public dataList: any = {};


    constructor(
        private jwtHelper: JwtHelperService,
        private http: HttpClient
    ) {
        this.apiHost = environment.apiHost;
    }

    static getUrl(url, queryParams = null) {

        const globalService = AppInjector.get(GlobalService);

        if (queryParams !== null) {
            if (url.search('\\?') === -1) {
                return globalService.apiHost + url + '?' + UrlFormatter.serializeQueryString(queryParams) +
                    '&' + GlobalService.debugString();
            } else {
                return globalService.apiHost + url + '&' + UrlFormatter.serializeQueryString(queryParams) +
                    '&' + GlobalService.debugString();
            }
        } else {
            return globalService.apiHost + url + '?' + GlobalService.debugString();
        }

    }

    static getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + GlobalService.getToken()
        });
    }

    static debugString() {
        let xdebug = '';
        if (environment.xdebug) {
            xdebug = 'XDEBUG_SESSION_START=PHPSTORM';
        }
        return xdebug;
    }

    static getToken(): any {
        return sessionStorage.getItem(environment.tokenName);
    }

    static _handleError(response: Response | any, modal = false) {

        const sharedService = AppInjector.get(SharedService);
        let errorMessage: ErrorResponse;

        // Connection error
        if (typeof response.error === 'undefined' || response.error.status === 0) {

            errorMessage = new ErrorResponse({
                message: 'Sorry, there was a connection error occurred. Please try again.',
                code: 0,
            });

        } else {

            errorMessage = new ErrorResponse(response.error.data);
            // if (errorMessage.status === 401 || errorMessage.status === 403) {
            if (errorMessage.status === 401 || errorMessage.status === 403) {

                if (errorMessage.status === 401) {
                    sharedService.unauthorizedAccess(response.error);
                } else {
                    modalError(errorMessage.message);
                }
                // errorMessage = response.error;

            } else if (modal && errorMessage.code != ERROR_CODES.CODE_ERROR_FIELDS) {
                modalError(errorMessage.message);
            }

        }

        return throwError(errorMessage);

    }

    // this function will show error on modal display automatically, if the error code is not 4220
    static handleErrorModal(response: Response | any) {
        return GlobalService._handleError(response, true);
    }

    static handleError(response: Response | any) {
        return GlobalService._handleError(response);
    }

    static getSettings(): GlobalSettings {
        const setting = new GlobalSettings();
        if (sessionStorage.getItem('backend-setting') !== null) {
            const _setting = JSON.parse(sessionStorage.getItem('backend-setting'));
            if (_setting) {
                Object.assign(setting, _setting);
            }
        }
        return setting;
    }

    isLoggedIn(): boolean {
        return this.jwtHelper.isTokenExpired() !== true;
    }

    getDataList(expand): Observable<any> {

        const subjectBehavior = new BehaviorSubject({});

        let qryDataListArray: string[];

        if (expand instanceof Array) {
            qryDataListArray = expand;
        } else {
            qryDataListArray = expand.split(',');
        }

        const returnDataList = {};
        // console.log('getDataList expand', JSON.parse(JSON.stringify(expand)));
        // console.log('getDataList returnDataList', JSON.parse(JSON.stringify(returnDataList)) );

        const query = [];
        qryDataListArray.forEach((key) => {
            this.qryDataList[key] = 0;
            if (typeof this.dataList[key] !== 'undefined') {
                returnDataList[key] = this.dataList[key];
            } else {
                query.push(key);
            }
        });
        // console.log('getDataList this.dataList', JSON.parse(JSON.stringify(this.dataList)) );
        // console.log('getDataList query', JSON.parse(JSON.stringify(query)) );

        if (query.length === 0) {

            return of(returnDataList);

        } else {

            return this.http.get<ResponseBody>(
                GlobalService.getUrl('/data-list', {expand: query.join(',')}),
                {headers: GlobalService.getHeaders()}).pipe(
                map(result => {

                        qryDataListArray.forEach((key) => {
                            if (typeof result.data[key] !== 'undefined') {
                                this.dataList[key] = result.data[key];
                                returnDataList[key] = result.data[key];
                            }
                        });

                        return returnDataList;

                    },
                    catchError(GlobalService.handleError)
                ));

            // qryDataListArray.forEach((key) => {
            //     this.qryDataObservable[key] = httpObservable;
            // });
            //
            // httpObservable.subscribe(data => {
            //     subjectBehavior.next(data);
            // });
            // return subjectBehavior.asObservable();

        }

    }

    loadGlobalSettingsFromSessionStorage(): void {
        if (sessionStorage.getItem('backend-setting') !== null) {
            this.setting = JSON.parse(sessionStorage.getItem('backend-setting'));
        }
    }


}
