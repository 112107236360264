interface NavAttributes {
    [propName: string]: any;
}

interface NavWrapper {
    attributes: NavAttributes;
    element: string;
}

interface NavBadge {
    text: string;
    variant: string;
}

interface NavLabel {
    class?: string;
    variant: string;
}

export interface NavData {
    id?: string;
    name?: string;
    url?: string;
    icon?: string;
    badge?: NavBadge;
    title?: boolean;
    children?: NavData[];
    variant?: string;
    attributes?: NavAttributes;
    divider?: boolean;
    class?: string;
    label?: NavLabel;
    wrapper?: NavWrapper;
    permissions?: string[];
    total_key_id?: string;
}

export const navItems: NavData[] = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        permissions: ['admin', 'staff'],
    },
    {
        name: 'User Management',
        url: '/user',
        icon: 'fa fa-user',
        permissions: ['viewSupplier', 'viewBuyer'],
        badge: {
            text: '',
            variant: 'danger'
        },
        total_key_id: 'kyc_bank_submission',
        children: [
            {
                name: 'Suppliers',
                url: '/user/list/supplier',
                // icon: 'fa fa-user',
                permissions: ['viewSupplier'],
            },
            {
                name: 'Buyers',
                url: '/user/list/buyer',
                // icon: 'fa fa-user',
                permissions: ['viewBuyer'],
            },
            // {
            //     name: 'Investor',
            //     url: '/user/list/investor',
            //     icon: 'fa fa-bank',
            //     permissions: ['manageBank'],
            //     badge: {
            //         text: '',
            //         variant: 'danger'
            //     },
            //     total_key_id: 'kyc_bank_submission'
            // },
            // {
            //     name: 'Users',
            //     url: '/user/list',
            //     icon: 'fa fa-user',
            // },
            // {
            //     name: 'KYC',
            //     url: '/user/kyc',
            //     icon: 'fa fa-user',
            //     badge: {
            //         text: '',
            //         variant: 'danger'
            //     },
            //     total_key_id: 'kyc_requests'
            // },
            // {
            //     name: 'Bank Details',
            //     url: '/user/bank',
            //     icon: 'fa fa-bank',
            //     permissions: ['manageBank'],
            //     badge: {
            //         text: '',
            //         variant: 'danger'
            //     },
            //     total_key_id: 'kyc_bank_submission'
            // },

        ]
    },
    // {
    //     name: 'Funds',
    //     url: '/fund',
    //     icon: 'fa fa-money',
    //     badge: {
    //         text: '',
    //         variant: 'danger'
    //     },
    //     total_key_id: 'fund_requests',
    //     children: [
    //         {
    //             name: 'Withdrawal',
    //             url: '/fund/request/list/withdrawal',
    //             icon: 'fa fa-money',
    //             badge: {
    //                 text: '',
    //                 variant: 'danger'
    //             },
    //             total_key_id: 'withdraw_requests',
    //         },
    //         {
    //             name: 'Deposit',
    //             url: '/fund/request/list/deposit',
    //             icon: 'fa fa-money',
    //             badge: {
    //                 text: '',
    //                 variant: 'danger'
    //             },
    //             total_key_id: 'deposit_requests'
    //         },
    //         {
    //             name: 'Payment',
    //             url: '/fund/request/list/payment',
    //             icon: 'fa fa-money',
    //             badge: {
    //                 text: '',
    //                 variant: 'danger'
    //             },
    //             total_key_id: 'payment_requests'
    //         },
    //     ],
    //     permissions: ['admin','staff'],
    // },
    // {
    //     name: 'Crowd Funding',
    //     url: '/crowd-funding',
    //     icon: 'fa fa-user',
    //     children: [
    //         // {
    //         //     name: 'Investments',
    //         //     url: '/crowd-funding/investment/list',
    //         //     icon: 'fa fa-user'
    //         // },
    //         {
    //             name: 'Projects',
    //             url: '/crowd-funding/project/list',
    //             icon: 'fa fa-user'
    //         },
    //     ]
    // },
    // {
    //     name: 'Loan',
    //     url: '/loan',
    //     icon: 'fa fa-file',
    //     badge: {
    //         text: '',
    //         variant: 'danger'
    //     },
    //     total_key_id: 'loan_requests',
    //     children: [
    //         // {
    //         //     name: 'Requests',
    //         //     url: '/loan/request',
    //         //     icon: 'fa fa-user',
    //         //     permissions: [
    //         //         'viewLoanRequest', 'createLoanRequest', 'updateLoanRequest', 'deleteLoanRequest'
    //         //     ],
    //         //     badge: {
    //         //         text: '',
    //         //         variant: 'danger'
    //         //     },
    //         //     total_key_id: 'loan_requests'
    //         // },
    //         {
    //             name: 'Listings',
    //             url: '/loan/list',
    //             icon: 'fa fa-file',
    //         },
    //         // {
    //         //     name: 'Listing',
    //         //     url: '/loan/list',
    //         //     icon: 'fa fa-user',
    //         //     children: [
    //         //         {
    //         //             name: 'All',
    //         //             url: '/loan/list',
    //         //             icon: 'fa fa-user',
    //         //         },
    //         //         {
    //         //             name: 'Funding',
    //         //             url: '/loan/list/funding',
    //         //             icon: 'fa fa-user',
    //         //         },
    //         //         {
    //         //             name: 'Repayment',
    //         //             url: '/loan/list/repayment',
    //         //             icon: 'fa fa-user',
    //         //         },
    //         //     ]
    //         // },
    //     ],
    //     permissions: ['admin','staff'],
    // },
    // {
    //     name: 'Logs',
    //     url: '/logs',
    //     icon: 'fa fa-gear',
    //     permissions: ['admin'],
    //     children: [
    //         {
    //             name: 'Email Logs',
    //             url: '/logs/email',
    //             icon: 'fa fa-laptop',
    //         },
    //         {
    //             name: 'File Logs',
    //             url: '/logs/file',
    //             icon: 'fa fa-laptop',
    //         },
    //         {
    //             name: 'Access Logs',
    //             url: '/logs/access',
    //             icon: 'fa fa-laptop',
    //         },
    //         {
    //             name: 'Activities',
    //             url: '/logs/activity/list',
    //             icon: 'fa fa-laptop',
    //             permissions: ['staff'],
    //         },
    //     ]
    // },
    {
        name: 'Invoice Management',
        url: '/invoice',
        icon: 'fa fa-sticky-note-o',
        children:
            [
                {
                    name: 'Lists',
                    // icon: 'fa fa-laptop',
                    url: '/invoice/lists',
                    permissions: ['viewInvoice'],
                }, 
            ],
        permissions: ['viewInvoice'],
    },
    {
        name: 'Report',
        url: '/report',
        icon: 'fa fa-file-pdf-o',
        children:
            [
                {
                    name: 'Onboarding',
                    // icon: 'fa fa-laptop',
                    url: '/report/supplier',
                    permissions: ['viewOnboarding'],
                },
                {
                    name: 'Financing',
                    // icon: 'fa fa-laptop',
                    url: '/report/financing',
                    permissions: ['viewFinancing'],
                },
                {
                    name: 'Due Repayment',
                    // icon: 'fa fa-laptop',
                    url: '/report/due-repayment',
                    permissions: ['viewDueRepayment'],
                },
                {
                    name: 'Repayment Status',
                    // icon: 'fa fa-laptop',
                    url: '/report/repayment-status',
                    permissions: ['viewRepaymentStatus'],
                },
                {
                    name: 'Statement',
                    // icon: 'fa fa-laptop',
                    url: '/report/statement',
                    permissions: ['viewStatement'],
                },
                
            ],
        permissions: ['viewOnboarding','viewFinancing','viewDueRepayment','viewRepaymentStatus','viewStatement'],
    },
    {
        name: 'Staff',
        url: '/staff/list',
        icon: 'fa fa-users',
        permissions: ['viewStaff',],
    },
    {
        name: 'Settings',
        icon: 'fa fa-gear',
        permissions: ['admin','manageSettings', 'manageAppVersion'],
        url: '/setting',
        children:
            [
                {
                    name: 'Global Settings',
                    // icon: 'fa fa-gear',
                    url: '/setting/global/list',
                    permissions: ['manageSettings'],
                },
                // {
                //     name: 'DBS Income Settings',
                //     icon: 'fa fa-gear',
                //     url: '/setting/dbs-list',
                //     permissions: ['manageSettings'],
                // },
                {
                    name: 'Rate Settings',
                    // icon: 'fa fa-gear',
                    url: '/setting/rate-settings',
                    permissions: ['manageSettings'],
                },
                {
                    name: 'API Integration',
                    // icon: 'fa fa-gear',
                    url: '/setting/api-integration',
                    permissions: ['manageSettings'],
                },
                // {
                //     name: 'App Versions',
                //     icon: 'fa fa-gear',
                //     url: '/setting/app-version/list',
                //     permissions: ['manageAppVersion'],
                // }
            ]
    },
    // {
    //     name: 'Core UI',
    //     url: '/coreui',
    //     icon: 'fa fa-gear',
    // }
];


