import {BaseObjectList} from '../../../model/base-object-list';
import {RepaymentRecord} from './repayment-record';

export class RepaymentRecordList extends BaseObjectList {

    rows: RepaymentRecord[];
    makeInstance(value) {
        return new RepaymentRecord(value);
    }

}
