import {Injectable} from "@angular/core";

@Injectable()
export class NumberFormatter {

    format(value, decimal = 0, conditional = false) {

        const val = value.toString().replace(/,/g, "");
        if (parseFloat(val) === 0 || val.trim() === '') {
            if (conditional || decimal === 0) {
                return '0';
            }
            return '0.00';
        }

        let parts = [];
        let right = '';

        if (decimal !== 0) {
            parts = (val * 1).toFixed(decimal).toString().split('.');
        } else {
            parts = val.split('.');
        }

        let left = parts[0].trim();
        if (left.length === 0) {
            left = "0";
        }

        let number = left.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (decimal !== 0) {
            right = '00';
            if (parts.length > 1) {
                if (parts[1] * 1 === 0) {
                    right = '00';
                } else {
                    right = parts[1].toString().substr(0, 2);
                }
            }
            if (conditional) {
                if (parseFloat(right) > 0) {
                    number += "." + right;
                }
            } else {
                number += "." + right;
            }
        }
        return number;

    }
}
