import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../model/global.service';
import {ResponseBody} from '../../../model/response-body';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RepaymentRecord} from './repayment-record';
import {RepaymentRecordList} from './repayment-record-list';

@Injectable()
export class RepaymentRecordService {

    constructor(
        private http: HttpClient,
    ) {

    }

    getById(id, query: any = null): Observable<RepaymentRecord> {

        return this.http.get<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record/' + id, query),
            {headers: GlobalService.getHeaders()}
        ).pipe(
            map(response => {
                return (new RepaymentRecord(response.data));
            }),
            catchError(GlobalService.handleError)
        );

    }

    create(data): Observable<RepaymentRecord> {

        const headers = GlobalService.getHeaders();
        return this.http.post<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record'),
            JSON.stringify(data),
            {headers: headers}
        ).pipe(
            map(response => {
                return (new RepaymentRecord(response.data));
            }),
            catchError(GlobalService.handleError)
        );
    }

    getAll(extendedQueries?: any): Observable<RepaymentRecordList> {

        let queries = {};
        if (extendedQueries) {
            queries = Object.assign(queries, extendedQueries);
        }
        return this.http.get<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record', queries),
            {headers: GlobalService.getHeaders()}
        ).pipe(
            map(
                response => {
                    return (new RepaymentRecordList(response.data));
                }
            ),
            catchError(GlobalService.handleError)
        );

    }

    approve(id) {
        const headers = GlobalService.getHeaders();

        return this.http.put<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record/' + id + '/approve'),
            null,
            {headers: headers}
        ).pipe(
            map(response => {
                return (new RepaymentRecord(response.data));
            }),
            catchError(GlobalService.handleErrorModal)
        );
    }

    update(id, data) {

        const headers = GlobalService.getHeaders();
        return this.http.put<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record/' + id),
            data,
            {headers: headers}
        ).pipe(
            map(response => {
                return response;
            }),
            catchError(GlobalService.handleErrorModal)
        );
    }

    delete(id) {

        const headers = GlobalService.getHeaders();
        return this.http.delete<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record/' + id),
            {headers: headers}
        ).pipe(
            map(response => {
                return response;
            }),
            catchError(GlobalService.handleErrorModal)
        );
    }

    preview(data) {

        return this.http.post<ResponseBody>(
            GlobalService.getUrl('/loan/repayment-record/preview'),
            JSON.stringify(data),
            {headers: GlobalService.getHeaders()}
        ).pipe(
            map(response => {
                return response.data;
            }),
            catchError(GlobalService.handleErrorModal)
        );

    }

}
