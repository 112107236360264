import {Injectable} from "@angular/core";
import {StaffService} from "./staff.service";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {ResponseBody} from "./response-body";
import {GlobalService} from "./global.service";
import {Fs} from "./fs";
import {catchError} from "rxjs/operators";

@Injectable()
export class FsDataService {

    constructor(
        private staffService: StaffService,
        private http: HttpClient,
        private globalService: GlobalService,
    ) {
    }

    upload(file, type): Observable<any> {

        const formData: FormData = new FormData();

        formData.append('file', file, file.name);
        formData.append('type', type);

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + GlobalService.getToken()
        });

        const req = new HttpRequest('POST',
            GlobalService.getUrl('/file'),
            formData,
            {
                headers: headers,
                reportProgress: true
            });

        const progress = new Subject<any>();

        this.http.request<ResponseBody>(req).pipe(
            catchError(GlobalService.handleError)
        ).subscribe(event => {

            if (event.type === HttpEventType.UploadProgress) {

                const percentDone = Math.round(100 * event.loaded / event.total);
                progress.next(percentDone);

            } else if (event instanceof HttpResponse) {

                const fileUploaded = new Fs(event.body.data);
                progress.next(fileUploaded);

                // Close the progress-stream if we get an answer form the API
                // The upload is complete
                progress.complete();

            }

        });

        return progress.asObservable();

    }

}
