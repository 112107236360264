<app-header
        [navbarBrandRouterLink]="['/dashboard']"
        [fixed]="true"
        [navbarBrandFull]="{src: 'assets/img/logo-1.png', width: 150,  alt: 'SCF'}"
        [navbarBrandMinimized]="{src: 'assets/img/logo-1.png', width: 30, alt: 'SCF'}"
        [sidebarToggler]="'lg'"
        [asideMenuToggler]="false">

    <ul class="nav navbar-nav ml-auto">
<!--        <li class="nav-item d-md-down-none">-->
<!--            <a class="nav-link" href="#">-->
<!--                <i class="icon-bell"></i>-->
<!--                <span class="badge badge-pill badge-danger">5</span>-->
<!--            </a>-->
<!--        </li>-->

        <li *ngIf="userData && meData" class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
<!--                <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"/>-->
                <img src="{{meData.photo_url}}" class="img-avatar" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center">
<!--                    <strong></strong> <br>-->
                    {{userData.username}}
                </div>
                <!--<a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>-->
                <a class="dropdown-item" [routerLink]="['/staff',userData.uid]"><i class="fa fa-user"></i>Update Profile</a>
                <!--<div class="divider"></div>-->
                <a class="dropdown-item" [routerLink]="['/logout']"><i class="fa fa-lock"></i> Logout</a>
            </div>
        </li>
    </ul>
</app-header>

<div class="app-body">
    <app-sidebar  [fixed]="true" [display]="'lg'">
        <app-sidebar-nav *ngIf="meData" [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>
    <main class="main">
        <!-- Breadcrumb -->
        <!-- breaking change 'cui-breadcrumb' -->
        <!-- <cui-breadcrumb> -->
            <!-- Breadcrumb Menu-->
            <!--<li class="breadcrumb-menu d-md-down-none">-->
                <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
                    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
                    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
                    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
                <!--</div>-->
            <!--</li>-->
        <!-- </cui-breadcrumb> -->
        <div class="container-fluid">
            <router-outlet></router-outlet>
            <br>
            <br>
        </div><!-- /.container-fluid -->
    </main>

</div>

<app-footer>
    <span>© Copyright {{year}}. PILON PTE LTD  </span>
    <span class="ml-auto">Powered by <a href="http://www.pilon.sg">Pilon.sg</a> <span style="font-size: 10px">{{version}}</span></span>
</app-footer>
