import {BaseModel} from '../../../model/base-model';
import {User} from '../../../model/user';

export const RepaymentRecordStatic = {
    STATUS_DELETED: -1,
    STATUS_PENDING: 0,
    STATUS_APPROVED: 1,

    TYPE_PAYMENT: 100,
    TYPE_PAYMENT_PARTIAL: 101,
    TYPE_PAYMENT_DUE_SETTLEMENT: 102,
    TYPE_PAYMENT_CUSTOM_PAYMENT: 103,
    TYPE_PAYMENT_SETTLE_ALL: 104,
    TYPE_PAYMENT_CAPITAL_ADJUSTMENT: 105,

    TYPE_INCUR_PENALTY: 500,
};

export class RepaymentRecord extends BaseModel {

    row_num: number;
    id: string;
    created_at: string;
    txn_date: string;
    total_amount: number;
    principal: number;
    interest: number;
    penalty: number;
    status: number;
    status_label: string;
    description: string;
    public_notes: string;
    internal_notes: string;
    is_hidden: boolean;
    created_by: User;
    approved_by: User;

    _spinnerApprove = false;
    _spinnerDelete = false;
    _spinnerUpdate = false;
    _showDetails = false;

    init(value) {
        if (value.created_by) {
            this.created_by = new User(value.created_by);
        }
        if (value.approved_by) {
            this.approved_by = new User(value.approved_by);
        }
        super.init(value);
    }

    isPending() {
        return this.status == RepaymentRecordStatic.STATUS_PENDING;
    }

    isApproved() {
        return this.status == RepaymentRecordStatic.STATUS_APPROVED;
    }

}
