import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ResponseBody} from './response-body';
import {GlobalService} from './global.service';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Stats} from './stats';

@Injectable({
    providedIn: 'root'
})
export class StatsService {

    public navBadgeStats: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public navBadgeStatsObservable = this.navBadgeStats.asObservable();

    constructor(
        private http: HttpClient
    ) {
    }

    getAll(): Observable<any> {

        return this.http.get<ResponseBody>(
            GlobalService.getUrl('/stats'), {
                headers: GlobalService.getHeaders()
            }
        ).pipe(
            map(
                response => {
                    return response.data;
                }
            ),
            catchError(GlobalService.handleError)
        );

    }

    private getBadges(): Observable<any> {

        return this.http.get<ResponseBody>(
            GlobalService.getUrl('/stats/badges'), {
                headers: GlobalService.getHeaders()
            }
        ).pipe(
            map(
                response => {
                    return response.data;
                }
            ),
            catchError(GlobalService.handleError)
        );

    }

    observeBadgesStat(): Observable<any> {
        this.updateNavBadges();
        return this.navBadgeStatsObservable;
    }

    updateNavBadges() {
        this.getBadges().subscribe(
            data => {
                this.navBadgeStats.next(data);
            }
        );
    }

    getDashboardStats(): Observable<Stats> {

        return this.http.get<ResponseBody>(
            GlobalService.getUrl('/stats/dashboard'), {
                headers: GlobalService.getHeaders()
            }
        ).pipe(
            map(
                response => {
                    console.log(response.data);
                    return (new Stats(response.data));
                }
            ),
            catchError(GlobalService.handleError)
        );

    }

}
