export class Fs {

    row_num: number;

    id: number;
    filename: string;
    url: string;
    status: number;
    is_image: boolean;
    status_label: string;
    created_at: string;
    updated_at: string;

    STATUS_DISABLED = 0;
    STATUS_ACTIVE = 1;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    getUrl(size = null) {
        return this.url + '&size=' + size;
    }

}
