import {Injectable} from '@angular/core';
import {NumberFormatter} from './numberFormatter';

@Injectable()
export class CurrencyFormatter {

    constructor(
        private numberFormatter: NumberFormatter
    ) {

    }

    format(number) {
        const globalSettings = JSON.parse(sessionStorage.getItem('backend-setting'));
        if (globalSettings && typeof globalSettings['currency_symbol'] !== 'undefined') {
            return globalSettings['currency_symbol'] + '' + this.numberFormatter.format(number, 2);
        } else {
            return this.numberFormatter.format(number, 2);
        }
    }

}
