export class Stats {

    key: string;
    encoding: string;
    value: string;

    // declare access to key
    booking_pending: number;
    user_total: number;
    vehicle_total: number;
    user_monthly: Array<any>;
    vehicle_monthly: Array<any>;

    login_hourly: Array<any>;
    login_daily: Array<any>;
    login_monthly: Array<any>;

    device_env: Array<any>;

    sales_monthly: Array<any>;

    private _stats: any[];
    public length = 0;

    constructor(values) {

        this.booking_pending = 0;
        this.user_total = 0;
        this.vehicle_total = 0;
        this.user_monthly = [];
        this.vehicle_monthly = [];
        this.login_hourly = [];
        this.login_daily = [];
        this.login_monthly = [];
        this.device_env = [];
        this.sales_monthly = [];

        this._stats = values;

        if (values) {
            this.length = values.length;
            values.forEach((stat) => {
                if (typeof this[stat.key] !== 'undefined') {
                    if (stat.encoding === 'json') {
                        this[stat.key] = JSON.parse(stat.value);
                    } else {
                        this[stat.key] = stat.value;
                    }
                }
            });
        }

    }

    static filterByAttr(values, attr, value) {
        let searched = null;
        if (values) {
            values.forEach((val) => {
                if (val[attr] !== 'undefined' && val[attr] === value) {
                    searched = val;
                }
            });
        }
        return searched;
    }

}
