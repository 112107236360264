import {catchError, map} from 'rxjs/operators';


import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {GlobalService} from './global.service';
import {Setting} from './setting';
import {Dbs} from './dbs';
import {StaffService} from './staff.service';
import {ResponseBody} from './response-body';

@Injectable()
export class SettingDataService {
    constructor(
        private globalService: GlobalService,
        private staffService: StaffService,
        private http: HttpClient
    ) {
    }

    public static getMetaTypes(): Array<any> {
        return [
            {
                label: 'Select',
                value: 'select'
            },
            {
                label: 'Number',
                value: 'number'
            },
            {
                label: 'Text',
                value: 'text'
            },
            {
                label: 'HTML',
                value: 'html'
            }
        ];
    }

    public static getIsPublicTypes(): Array<any> {
        return [
            {
                label: 'Public',
                value: 1
            },
            {
                label: 'Private',
                value: 0
            }
        ];
    }

    // POST /v1/setting
    addSetting(setting: Setting): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .post<ResponseBody>(
                GlobalService.getUrl('/setting'),
                JSON.stringify(setting),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // DELETE /v1/setting/1
    deleteSettingById(id: number): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .delete<ResponseBody>(GlobalService.getUrl('/setting/' + id), {
                headers: headers
            }).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // PUT /v1/setting/1
    updateSettingById(setting: Setting): Observable<any> {
        const headers = GlobalService.getHeaders();

        return this.http
            .put<ResponseBody>(
                GlobalService.getUrl('/setting/' + setting.id),
                JSON.stringify(setting),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    return response;
                }),
                catchError(GlobalService.handleError));
    }

    // GET /v1/setting
    getAllSettings(query = {}): Observable<Setting[]> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/setting', Object.assign({'sort': 'meta_key'}, query)),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    return <Setting[]>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    refreshGlobalSettings(): void {

        // get settings
        this.globalService.loadGlobalSettingsFromSessionStorage();

        this.getAllSettings().subscribe(settings => {

            settings.forEach(setting => {
                switch (setting.meta_type) {
                    case 'select':
                    case 'text':
                        this.globalService.setting[setting.meta_key] = setting.meta_value;
                        break;
                    case 'number':
                        this.globalService.setting[setting.meta_key] = +setting.meta_value;
                        break;
                }
                sessionStorage.setItem(
                    'backend-setting',
                    JSON.stringify(this.globalService.setting)
                );
            });

        });
    }

    // GET /v1/setting/public
    getAllSettingsPublic(): Observable<Array<any>> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/public'), {
                // headers: headers
            }).pipe(
                map(response => {
                    return <Array<any>>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    getAllSettingsGlobal(): Observable<Array<any>> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/global'), {
                headers: headers
            }).pipe(
                map(response => {
                    return <Array<any>>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    getSettingById(id: number): Observable<Setting> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/' + id), {
                headers: headers
            }).pipe(
                map(response => {
                    return <Setting>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    getByMetaKey(meta_key: string): Observable<Setting> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/global', {meta_key}), {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data[meta_key];
                }),
                catchError(GlobalService.handleError));
    }


    getIpiIntegration() {
        const headers = GlobalService.getHeaders();
        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/api-integration'), {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }


    updateIpiIntegration(data) {
        const headers = GlobalService.getHeaders();
        return this.http
            .put<ResponseBody>(
                GlobalService.getUrl('/setting/api-integration'),
                data, {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }

    updateGlobalRate(data) {
        const headers = GlobalService.getHeaders();
        return this.http
            .put<ResponseBody>(
                GlobalService.getUrl('/setting/rate-setting'),
                data, {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }

    // Dbs Income
    getDbsIncome(query = {}): Observable<Setting[]> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(
                GlobalService.getUrl('/setting/dbs-lists'),
                {
                    headers: headers
                }
            ).pipe(
                map(response => {
                    return <Setting[]>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    // POST
    addDbsIncome(data): Observable<any> {
        const headers = GlobalService.getHeaders();
        return this.http
            .post<ResponseBody>(
                GlobalService.getUrl('/setting/dbs-create'),
                data, {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }

    // VIEW
    getDbsById(id: number): Observable<Setting> {
        const headers = GlobalService.getHeaders();

        return this.http
            .get<ResponseBody>(GlobalService.getUrl('/setting/dbs-view/' + id), {
                headers: headers
            }).pipe(
                map(response => {
                    return <Setting>response.data;
                }),
                catchError(GlobalService.handleError));
    }

    updateDbsIncome(id, data) {
        const headers = GlobalService.getHeaders();
        return this.http
            .put<ResponseBody>(
                GlobalService.getUrl('/setting/dbs-update/' + id),
                data, {
                headers: headers
            }).pipe(
                map(response => {
                    return response.data;
                }),
                catchError(GlobalService.handleError));
    }

}
